import React from 'react';
import './index.css';
import { useState } from 'react';



function App() {
  const[bgColor1, setbgColor1] = useState('#1e5263');
  const[bgColor2, setbgColor2] = useState('#3590ae');
  const [hoverShadowColor, setHoverShadowColor] = useState('hover:shadow-gray-900/70');
  const [bttext,setbuttext] = useState("Dark mode");

  const toggleBG = () => {
    setbgColor1(prevColor => prevColor === "#1e5263"? "#1e1e1e" : "#1e5263");
    setbgColor2(prevColor => prevColor === "#3590ae"? "#3a3a3a" : "#3590ae");
    setHoverShadowColor(prevShadow => 
      prevShadow === 'hover:shadow-gray-900/70' ?  'hover:shadow-cyan-500/50': 'hover:shadow-gray-900/70');
    setbuttext(prevText => prevText === "Dark mode"? "Light mode": "Dark mode");
  };

  const projects = [
    { id: 1, title: "Autoscaling Image Classifier using AWS", description: "Image Classifier deployed on AWS", link: "#", github: "https://github.com/karthikravikumar3/Autoscaling-Image-Classifier-using-AWS/tree/main" },
    { id: 2, title: "Hybrid Movie Recommender", description: "Movie recommendation system using multiple Machine Learning Algorithms", link: "#", github: "https://github.com/karthikravikumar3/CSE572-HybridMovieRecommender" },
    { id: 3, title: "Conditional Handover in 4G/5G Systems", description: "Conditional Handover in Wireless systems implemented using srsRan Suite", link: "#", github: "https://github.com/karthikravikumar3/Conditional-Handover" },
    { id: 4, title: "A Study on Kernel Address Layout Space Randomization (KALSR)", description: "A Study on Kernel Address Layout Space Randomization (KALSR)", link: "#", github: "https://github.com/karthikravikumar3/KASLR" }
  ];

  const skills = [
    "React", "JavaScript", "Node.js", "Express","Python", "AWS", "Docker", "Kubernetes", "Pandas", "C++","HTML","CSS","SQL","Jenkins" 
  ];

  const experiences = [
    { id: 1, role: "Full Stack Developer", company: "Medharbor", desc:["Developed and maintained server-side applications using Node.js, ensuring efficient data processing and seamless integration with front-end components."," Designed and implemented RESTful APIs for various web services, facilitating communication between front-end and back-end systems."," Utilized databases such as MongoDB Atlas to store and manage application data, employing efficient querying techniques."], period: "June 2024 - Present" },
    { id: 2, role: "R&D Software Developer", company: "Nokia",desc:[
      "Expertise in managing the UE simulator (Airphone) in L3 layer on 5G SA and NSA configurations, 3gpp Specifications related product development (C++, Python).",
      "Experience in simulating, analysis and testing 5G systems and network configurations using Gtest and Pytest frameworks, designed automated test cases and actively troubleshot and resolved a hundred percent of bugs and support issues faced.",
      "Collaborated with cross-functional teams in different locations to drive research and development projects from concept to production.",
      "Directed the migration of the Linux server system to Yocto Project, thereby reducing server and library compatibility checking workload by 20%."],
     period: "Jan 2021 - July 2022" },
    { id: 3, role: "Devops Intern", company: "SAP Labs",desc:[
      "Expertise in automating the build, deployment, and scaling of applications using tools such as Jenkins, Kubernetes, and Docker.",
      "Experience in Linux, Shell Scripting, Python, and Groovy programming. Familiarity with infrastructure as code using Terraform and cloud technologies like AWS.",
      "Developed and maintained an Automated CI/CD testing pipeline in Jenkins with Hashicorp Stack, ensuring efficient and streamlined software development processes."], period: "Jan 2020 - July 2021" },
  ];


  return (
    <div style={{ backgroundColor: bgColor2 }} className="min-h-screen  text-white">
      <header className="container mx-auto px-4 py-8">
        <div className="flex justify-between items-center">
          
          <h1 className="text-4xl font-bold mt-4">Karthik Ravikumar</h1>
          <nav className="space-x-4">
            <a href="#projects" className="hover:text-cyan-400 transition-colors">Projects</a>
            <a href="#skills" className="hover:text-cyan-400 transition-colors">Skills</a>
            <a href="#experience" className="hover:text-cyan-400 transition-colors">Experience</a>
          </nav>
        </div>
        <p className="mt-4 text-xl text-gray-300">Full Stack Developer | Problem Solver</p>
        <p className="mt-1 text-x text-gray-300">MS in CS, Arizona State University 2024</p>
        <div className="mt-6 flex space-x-4">
          <button  style={{backgroundColor: bgColor1}} className="p-2 border border-gray-600 rounded-full hover:bg-gray-700 transition-colors">
            <a href = "https://github.com/karthikravikumar3" target="blank">Git</a>
          </button>
          <button style={{backgroundColor: bgColor1}} className="p-2 border border-gray-600 rounded-full hover:bg-gray-700 transition-colors">
            <a href = "mailto:karthikravikumar3@outlook.com"> Mail</a>

          </button>
          <button style={{backgroundColor: bgColor1}} className="p-2 border border-gray-600 rounded-full hover:bg-gray-700 transition-colors">
            <a href = "https://www.linkedin.com/in/karthik-ravi-kumar" target="blank">LinkedIn</a>
          </button>
          {/*<button className="p-2 border border-gray-600 rounded-full hover:bg-gray-700 transition-colors">
            <a href = "public/logo192.png" download="Karthik_Ravikumar_Resume.png" >Resume</a>
          </button>*/}
        </div>
        <div>
          <button onClick = {toggleBG} className="fixed top-[5%] right-[19%] text-lg py-2 px-4 bg-gray-700 rounded-full hover:bg-gray-600 transition-colors transition-transform duration-300 transform hover:scale-105 hover:shadow-lg hover:shadow-cyan-500/50">
            {bttext}
          </button>
        </div>
      </header>
      <br></br>
      <main className="container mx-auto px-4 py-12 space-y-24">
        <section id="projects">
          <h2 className="text-3xl font-bold mb-8">Projects</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map((project) => (
  <div 
    key={project.id} 
    style = {{backgroundColor: bgColor1}}
    className={ `border border-gray-700 rounded-lg overflow-hidden flex flex-col h-full transition-transform duration-300 transform hover:scale-105 hover:shadow-lg ${hoverShadowColor} `}
  >
    <div className="p-6 flex-grow">
      <h3 className="text-xl font-semibold text-cyan-400 mb-2">{project.title}</h3>
      <p className="text-gray-300 mb-4">{project.description}</p>
    </div>
    
    <div className="flex justify-end p-3">
      <a 
        href={project.github} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-gray-700 rounded-lg hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-600"
      >
        Code
      </a>
    </div>
  </div>
))}

          </div>
        </section>

        <section id="skills">
          <h2 className="text-3xl font-bold mb-8">Tech Skills</h2>
          <div className="flex flex-wrap gap-4">
            {skills.map((skill, index) => (
              <span style={{ backgroundColor: bgColor1 }} key={index} className={`text-lg py-2 px-4 rounded-full hover:bg-gray-600 transition-colors transition-transform duration-300 transform hover:scale-105 hover:shadow-lg ${hoverShadowColor}`}>
                {skill}
              </span>
            ))}
          </div>
        </section>

        <section id="experience">
          <h2 className="text-3xl font-bold mb-8">Work Experience</h2>
          <div className="space-y-8">
            {experiences.map((exp) => (
              <div style={{ backgroundColor: bgColor1 }} key={exp.id} className={`border border-gray-700 rounded-lg p-6 transition-transform duration-300 transform hover:scale-105 hover:shadow-lg ${hoverShadowColor}`}>
                <h3 className="text-xl font-semibold text-cyan-400 mb-2">{exp.role}</h3>
                <p className="text-gray-300 mb-2">{exp.company}</p>
                <ul>
                  {exp.desc.map((point,index)=> (
                  <li key={index}>{point}</li>
                  ))}
                </ul>

                <p className="text-gray-400">{exp.period}</p>
              </div>
            ))}
          </div>
        </section>
      </main>

      <footer className="container mx-auto px-4 py-8 text-center text-gray-400">
        <br></br>
      </footer>
      <p> </p>
    </div>
  );
}

export default App;